body.lightmode {
	background-color: white;
}
body.darkmode {
	background-color: #121212;
}

.darkmode .outer_fall_back_div {
	background-color: #121212;
}

.darkmode .react-loader-text {
	color: white;
}

body:has(.dark-theme) {
	background-color: #121212;
}
body:has(.light-theme) {
	background-color: white;
}

.db-page-container {
	background: var(--root-bg-clr);
	/* height: calc(100svh); */
	display: grid;
	/* grid-template-rows: auto; */
	grid-template-areas:
		'header'
		'body'
		'files';
	gap: 1rem;
}

/* .db-page-container:has(.db-body-container) {
	gap: 1rem;
} */

.db-body-hide {
	display: none;
}

.db-body-grid-area {
	grid-area: body;
}

.db-body-container {
	/* padding will create overflow, dont add padding*/
	display: flex;
	flex-direction: column;
	/* border: 2px solid red; */
	width: min(95em, 95%);
	margin-inline: auto;
	gap: 2.8rem;
}

.db-grid-body {
	/* padding will create overflow, dont add padding*/
	display: grid;
	/* grid-template-columns: repeat(auto-fit, min(400px, 100%)); */
	grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
	gap: 3rem;
	width: 100%;
	justify-content: center;
	/* background-color: cadetblue; */
}

.db-grid-body:has(.profile-wrapper, .user-profile, .page_billing) {
	display: flex;
}

.db-flex-body {
	display: flex !important;
}

/* @media (width > 1600px) {
	.db-grid-body {
		grid-template-columns: repeat(3, minmax(min(400px, 100%), 1fr));
	}
} */
