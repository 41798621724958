.reset_passbutton {
	box-shadow: 0px 18px 35px rgba(152, 64, 210, 0.15);
	border-radius: 5px;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 800 !important;
	line-height: 28px !important;
	letter-spacing: 0em !important;
	text-align: center !important;
	margin-top: 0.5rem !important;
}
.xanadu-reset_passbutton {
	background-image: linear-gradient(
		to right,
		#4b6cb7 0%,
		#182848 51%,
		#4b6cb7 100%
	);
	margin: 10px;
	padding: 15px 20px;
	text-align: center;
	text-transform: none;
	transition: 0.5s !important;
	background-size: 200% auto;
	height: 60px !important;
	color: white !important;
	box-shadow: 0 0 20px #eee;
	border-radius: 5px;
	display: block;
	border: none;
	cursor: pointer;
}

.xanadu-reset_passbutton:hover {
	background-position: right center; /* change the direction of the change here */
	color: #fff;
	text-decoration: none;
}

.register-grid {
	padding-top: 5rem !important;
	padding-bottom: 5rem !important;
	padding-left: 1rem !important;
	padding-right: 1rem !important;
}

.reset-password {
	position: absolute !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	background-color: #ffffff !important;
	border: '2px solid #000';
	box-shadow: 24px;
	width: 100% !important;
	max-width: 770px !important;
	border-radius: 9px;
	overflow: hidden;
}

.password-text {
	font-family: 'Inter', sans-serif !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 17px !important;
	letter-spacing: -0.03em !important;
	/* text-align: center !important; */
	margin-bottom: 0.7rem !important;
	margin-top: 1rem !important;
	/* color: #555555 !important; */
}

.set-text {
	font-family: 'Inter', sans-serif !important;
	font-size: 15px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 21px !important;
	letter-spacing: 0em !important;
	text-align: center !important;
	margin-bottom: 0.5rem !important;
	color: #444444 !important;
}

.reset-headtext {
	font-family: 'Inter', sans-serif !important;
	font-size: 30px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 36px !important;
	letter-spacing: 0em !important;
	text-align: center !important;
	/* display: flex !important; */
	align-items: center !important;
	margin-bottom: 0.5rem !important;
	/* color: #2e3338 !important; */
}

.input-fieltext {
	font-family: 'Inter', sans-serif !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 22px !important;
	letter-spacing: 0em !important;
	text-align: left !important;
	margin-bottom: 0.6rem !important;
	/* width: 390px !important; */
	height: 60px !important;
}

.otp-filed {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	flex-wrap: wrap !important;
}

.otp-filed > input {
	/* width: 30px !important;
	height: 35px !important;
	margin-right: 15px !important;
	margin-bottom: 10px !important;*/
	margin-top: 10px !important;
	justify-content: center !important;
	background-color: inherit !important;
	border: 1px solid #999999;
	box-sizing: border-box !important;
	border-radius: 5px !important;
	width: 30px !important;
	height: 35px !important;
}

@media (max-width: 1000px) {
	.register-grid {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
	}
}
