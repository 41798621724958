.dB-desc-heading {
	font-family: 'Nunito-sans', sans-serif;
	font-style: normal;
}

.dB-text-gradient {
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	/* background: linear-gradient(300deg, var(--brand-color-3), #46096f); */
	background: linear-gradient(
		180deg,
		var(--brand-color-1) 0%,
		var(--brand-color-3) 178.85%
	);

	-webkit-background-clip: text;
	background-clip: text;
	color: gray;
}

.dB-desc-desc {
	font-family: 'Nunito-sans', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 23px;
	/* width:0%; */
	color: #7e7d7d;
}

.dB-expand-btn {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
	gap: 0.5em;
	transition: padding 250ms linear !important;
}

.dB-expand-btn:hover {
	padding-right: 2em !important;
}

.dB-expand-btn > .btn_icon {
	position: absolute;
	opacity: 0;
	transition: opacity 100ms linear;
	right: 0.5em;
}
.dB-expand-btn:hover > .btn_icon {
	opacity: 1;
	transition: opacity 250ms linear;
}

@media (width < 48rem) {
	.dB-desc-container {
		grid-column: span 2;
		justify-content: flex-start;
		min-height: fit-content;
	}
}

@media (width < 30rem) {
	.dB-desc-container {
		grid-column: span 1;
	}
}
