.custom_btn {
	--btn-clr1: rgb(1, 17, 23);
	--btn-clr2: var(--brand-color-1);
	background-image: linear-gradient(
		120deg,
		var(--btn-clr1) 50%,
		var(--btn-clr2) 50%
	);
	background-size: 250%;
	background-position: right;
	outline: transparent;
	border-radius: 16px;
	color: currentColor;
	line-height: 16px;
	font-weight: 900;
	font-size: 11px;
	font-family: 'Inter', sans-serif;
	transition: transform 150ms ease, box-shadow 150ms ease,
		background-position 250ms linear, outline-offset 250ms ease;
	cursor: pointer;
}

.custom_btn:focus,
.custom_btn:focus-visible {
	/* outline: 2px solid var(--btn-clr2); */
	outline-offset: 2px;
}

.custom_btn:hover {
	/* transform: scale(1.05); */
	box-shadow: 0px 0px 6px rgb(156 39 176 / 22%);
	background-position: left;
}

/* button variants */

.custom_btn.solid {
	border: none;
	--btn-clr1: rgb(1, 17, 23);
	--btn-clr2: var(--brand-color-1);
	color: white;
}
.custom_btn.solid:hover {
	text-shadow: 0 0 6px rgb(255 255 255 / 40%);
}

.custom_btn.outlined {
	background: transparent;
	border: 1px solid var(--brand-color-1);
	color: var(--brand-color-1);
}

/* button sizes */

.custom_btn.default {
	padding: 4px 8px;
	min-width: 98px;
}
.custom_btn.large {
	padding: 8px 12px;
	/* height: 26px; */
	min-width: 98px;
	font-size: 13px;
}
.custom_btn.small {
	height: min-content;
	min-width: min-content;
}

.custom_btn.fullWidth {
	flex: 1;
	width: 100%;
}
