.custom-card {
	border-radius: 12px;
	/* aspect-ratio: 1 / 0.534; */
	/* min-height: 245px; */
	/* min-width: min(440px, 100%); */
	overflow: hidden;
	user-select: none;
	transition: all 200ms ease;
}

.custom-card.custom-hover {
	transition: scale 250ms ease, outline-offset 250ms, outline-style 250ms;
}

.custom-card.custom-hover:hover {
	scale: 1.02;
}

.custom-outlined {
	background-color: transparent;
	/* border: 1px solid var(--border-clr2); */
}

.custom-solid {
	/* box-shadow: 0px 6px 16px var(--shadow1); */
	/* background-color: var(--bg-clr2); */
	/* border: 1px solid var(--border-clr2); */
}

.custom-card.slide-in-bottom {
	animation: slide-from-btm 1s cubic-bezier(0, 0.55, 0.45, 1) 0s 1 normal
		forwards;
}

.custom-card.fade-in {
	-webkit-animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.custom-card.slide-in-right {
	animation: slideRight 1s cubic-bezier(0, 0.55, 0.45, 1) 0s 1 normal backwards;
}

@media (width < 48em) {
	.custom-card {
		grid-column: 1 / -1;
	}
}

@media (width < 30em) {
	.custom-card {
		grid-column: span 1 !important;
	}
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-from-btm {
	0% {
		opacity: 0;
		transform: translateY(150px);
	}
	50% {
		opacity: 0.35;
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes slide-from-btm {
	0% {
		opacity: 0;
		transform: translateY(150px);
	}
	50% {
		opacity: 0.35;
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes slideRight {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
