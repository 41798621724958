.img-linear-bg {
	background-image: conic-gradient(
		at top right,
		var(--white-200) 179deg,
		var(--white-100) 253deg
	);
}
.img-linear-bg-dark {
	background-image: conic-gradient(
		at top right,
		var(--auth-100) 179deg,
		var(--auth-200) 253deg
	);
}
.xanadu-img-bg {
	background: url('../../../assets/images/XanaduLoginBackground.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.qbraid-full-logo-xanadu {
	position: relative;
	left: 36%;
	bottom: 23%;
	width: 80px;
	user-select: none;
	pointer-events: none;
}

.circle-shape {
	display: block;
	aspect-ratio: 1;
	border-radius: 50%;
	user-select: none;
}

.circle-shape.large {
	width: 150px;
}
.circle-shape.small {
	width: 25px;
}

.circle-shape.grad-1 {
	background-image: linear-gradient(
		51deg,
		var(--accent-color-1) 10%,
		var(--accent-color-2) 100%
	);
}
.circle-shape.grad-2 {
	background-image: linear-gradient(
		to left,
		var(--accent-color-1) 15%,
		var(--accent-color-2) 100%
	);
}
.circle-shape.grad-3 {
	background-image: linear-gradient(
		to left,
		var(--accent-color-1) 0%,
		var(--accent-color-2) 100%
	);
}

.pos-top-right {
	position: absolute;
	top: 0;
	right: 0;
	transform: translateX(50%);
}

.pos-bottom-left {
	position: absolute;
	left: 0;
	bottom: 0;
	transform: translateX(-50%);
}

.pos-bottom-left .position_shift {
	position: absolute;
	top: 0;
	right: 0;
	transform: translateX(100%);
}
