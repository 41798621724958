@media (width < 1410px) {
	.span-md {
		grid-column: span 2;
		padding-bottom: 2em;
	}
}
@media (width < 40em) {
	.span-md {
		display: none;
	}
}

.center_logo {
	width: 100% !important;
	height: 100%;
	display: flex;
}
