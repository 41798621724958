@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins&display=swap');
.create_button {
	box-shadow: 0px 18px 35px rgba(152, 64, 210, 0.15);
	border-radius: 5px;
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 800 !important;
	line-height: 28px !important;
	letter-spacing: 0em !important;
	text-align: center !important;
	margin-top: 0.5rem !important;
}
.xanadu-reset-button {
	background-image: linear-gradient(
		to right,
		#4b6cb7 0%,
		#182848 51%,
		#4b6cb7 100%
	);
	margin: 10px;
	padding: 15px 20px;
	text-align: center;
	text-transform: none;
	transition: 0.5s !important;
	background-size: 200% auto;
	/* width: 390px !important; */
	color: white !important;
	box-shadow: 0 0 20px #eee;
	border-radius: 5px;
	display: block;
	border: none;
	cursor: pointer;
}

.xanadu-reset-button:hover {
	background-position: right center; /* change the direction of the change here */
	color: #fff;
	text-decoration: none;
}
.user-password-grid {
	margin-top: 6rem !important;
	margin-bottom: 6rem !important;
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
	border-radius: 10px;
}
.reset-grid {
	position: absolute !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	background-color: #ffffff;
	border: '2px solid #000';
	box-shadow: 24px;
	width: 100% !important;
}

.email-text {
	display: flex !important;
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 17px !important;
	letter-spacing: -0.03em !important;
	text-align: center !important;
	margin-bottom: 0.5rem !important;
	margin-top: 0.5rem !important;
	/* color: #555555 !important; */
}

.forget-text {
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 15px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 21px !important;
	letter-spacing: 0em !important;
	text-align: center !important;
	margin-bottom: 0.5rem !important;
	/* color: #444444 !important; */
}
.reset-text {
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 30px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 36px !important;
	letter-spacing: 0em !important;
	text-align: center !important;
	margin-bottom: 0.5rem !important;
	/* color: #2e3338 !important; */
}
.input-text {
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 22px !important;
	letter-spacing: 0em !important;
	text-align: left !important;
	/* width: 390px !important; */
	height: 60px !important;
}
@media (max-width: 1000px) {
	.user-password-grid {
		padding-right: 1rem !important;
	}
}
