.facade-container {
	width: 100%;
	height: 100%;
	margin: auto;
	display: flex;
}

.vid-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	animation: skeleton-loading 1s linear 5 alternate;
}

.vid-container h1 {
	color: var(--alt-font-clr1);
}
.vid-container h2 {
	color: var(--font-clr1);
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 20%, 80%);
	}
	100% {
		background-color: hsl(200, 20%, 95%);
	}
}
