@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins&display=swap');

.grid-signup {
	max-height: 97%;
	border: '2px solid #000';
	box-shadow: 24px;
	width: 100% !important;
	max-width: 936px !important;
	overflow-y: hidden;
	border-radius: 10px !important;
	background-color: #f5f5f5f5 !important;
}

.google-button {
	/* max-width: 390px !important; */
	height: 60px !important;
	text-transform: none !important;
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 22px !important;
}

.linktext {
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 21px !important;
	margin-left: 00.3rem !important;
	margin-right: 0.3rem !important;
	cursor: pointer !important;
}

.textMargin {
	width: 96%;
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 22px !important;
	/* background-color: inherit !important; */
	/* margin-bottom: 5rem !important; */
}
.pass-text {
	/* font-family: 'Poppins', sans-serif !important; */
	font-size: 12px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 18px !important;
	margin-top: 1rem !important;
	margin-bottom: 1.5rem !important;
	/* color: #414345 !important; */
}

.signup_button {
	box-shadow: 0px 18px 35px rgba(152, 64, 210, 0.15) !important;
	border-radius: 5px;
	margin-top: 1rem !important;
	margin-bottom: 0.5rem !important;
	font-size: 16px !important;
	font-weight: 800 !important;
	line-height: 28px !important;
	letter-spacing: 0em !important;
	text-align: center !important;
}
.xanadu-signup-button {
	background-image: linear-gradient(
		to right,
		#4b6cb7 0%,
		#182848 51%,
		#4b6cb7 100%
	);
	margin: 10px;
	padding: 15px 20px;
	text-align: center;
	text-transform: none;
	transition: 0.5s !important;
	background-size: 200% auto;
	color: white !important;
	box-shadow: 0 0 20px #eee;
	border-radius: 5px;
	display: block;
	border: none;
	cursor: pointer;
}

.xanadu-signup-button:hover {
	background-position: right center; /* change the direction of the change here */
	color: #fff;
	text-decoration: none;
}

.textfieldMargin {
	/* max-width: 390px !important; */
	height: 60px !important;
	margin-top: 0.4rem !important;
	margin-bottom: 1rem !important;
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 22px !important;
	/* background-color: #ffffff !important; */
}

.textfieldMargin:focus {
	color: var(--brand-color-1) !important;
}

.signup-sectext {
	text-align: center !important;
	font-family: 'Poppins', sans-serif !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #414345 !important;
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.access-text {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	text-align: left !important;
	/* color: #2e3338 !important; */
}

.check-mail-text {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	text-align: center !important;
	/* color: #2e3338 !important; */
}

.link-button {
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 21px !important;
	cursor: pointer !important;
	margin-left: 0.3rem !important;
}

.form-Grid {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
	padding-left: 3rem;
	padding-right: 3rem !important;
	background-color: inherit !important;
	box-shadow: 0px 10px 55px 0px #00000012;
	height: 80vh;
	max-height: 750px;
	overflow-y: scroll;
}
.imgGridSinUp {
	margin: 0 !important;
	padding: 0 !important;
	/* height: 100% !important; */
}
.sub_form {
	margin-bottom: 0 !important;
}
.error-text {
	text-align: center !important;
	font-size: small !important;
	color: #d32f2f !important;
}
.qBraid-logo {
	object-fit: cover !important;
}

@media (max-width: 1000px) {
	.form-Grid {
		padding-top: 3rem;
		padding-bottom: 3rem;
		padding-left: 1rem !important;
		padding-right: 1rem !important;
		height: 100vh !important;
		max-height: 100vh !important;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.grid-signup {
		max-height: 100% !important;
		overflow-y: auto !important;
	}
}

@media (max-width: 640px) {
	.grid-signup {
		height: 100vh;
	}
	.form-Grid {
		height: 100vh !important;
		max-height: 100vh !important;
		padding: 1rem !important;
		background-color: white !important;
		overflow: auto;
	}
}
