@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Poppins&display=swap');

.login_button {
	box-shadow: 0px 18px 35px rgba(152, 64, 210, 0.15) !important;
	font-weight: 800 !important;
	font-size: 16px !important;
	font-style: normal;
	text-align: center;
}
.xanadu-login-button {
	background-image: linear-gradient(
		to right,
		#4b6cb7 0%,
		#182848 51%,
		#4b6cb7 100%
	);
	margin: 10px;
	padding: 15px 20px;
	text-align: center;
	text-transform: none;
	transition: 0.5s !important;
	background-size: 200% auto;
	color: white !important;
	box-shadow: 0 0 20px #eee;
	border-radius: 5px;
	display: block;
	border: none;
	cursor: pointer;
}

.xanadu-login-button:hover {
	background-position: right center; /* change the direction of the change here */
	color: #fff;
	text-decoration: none;
}
.box-style {
	position: absolute !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	max-width: 830px !important;
	width: 100% !important;
	background-color: #e5e5e5 !important;
	border: '2px solid #000';
	box-shadow: 24px;
	box-shadow: 0px 10px 55px 0px #00000012 !important;
	overflow-y: hidden !important;
	border-radius: 10px !important;
}
.google-button {
	/* width: 390px; */
	height: 60px;
	text-transform: none !important;
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 22px !important;
}
.imgGrid {
	background-color: inherit !important;
	box-shadow: 0px 10px 55px rgba(0, 0, 0, 0.07) !important;
}

.formGrid {
	padding: 4rem !important;
	box-shadow: 0px 10px 55px rgba(0, 0, 0, 0.07) !important;
	background-color: inherit !important;
}
.sec-text {
	font-family: 'Poppins', sans-serif !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 16px !important;
	line-height: 22px !important;
	color: #414345 !important;
	margin-top: 1rem !important;
}
.keep-text {
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 22px !important;
	letter-spacing: 0em !important;
	text-align: left !important;
	color: inherit !important;
}
.textfiledMargin {
	/* width: 390px !important; */
	height: 60px !important;
	margin-top: 1rem !important;
	margin-bottom: 0.5rem !important;
	font-family: 'Inter', sans-serif !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 22px !important;
}
.link-style {
	/* font-family: 'Inter', sans-serif !important; */
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 22px !important;
	/* margin-top: 0.5rem !important;
	margin-bottom: 1rem !important; */
	cursor: pointer;
}
.login_new {
	/* margin-right: 4rem !important; */
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

@media (max-width: 1000px) {
	.formGrid {
		padding: 2rem !important ;
		max-height: 100vh !important;
		overflow: auto !important;
	}
	.imgGrid {
		display: none;
	}
}
@media (max-width: 600px) {
	.formGrid {
		padding: 1rem !important;
		background-color: inherit !important;
	}
}
.qBraid-logo {
	object-fit: cover;
}

.login-modal .MuiBackdrop-root {
	backdrop-filter: blur(10px) grayscale(1);
}
.xanadu-login-modal {
	background: linear-gradient(
		to top,
		#173583,
		#004d95,
		#0064a3,
		#007aad,
		#008fb4
	);
}
