.bg-footer {
	background-color: var(--bg-clr1);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: hidden;
	padding: 0.5em 0 0.5em 0;
	/* margin-top: 2em; */
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 10;
	min-height: 30px;
}

.footer-dark-theme {
	background-color: #121212;
}

.contact-info {
	margin-top: 0px;
}
.bg-footer a {
	text-decoration: none;
}

.footer_social__icons {
	object-fit: contain;
	height: 30px;
	width: 30px;
	cursor: pointer;
}

.footer_social_icons {
	width: 30px;
	height: 30px;
	cursor: pointer;
}
.footer_social__icons:hover {
	border-radius: 50%;
	background-color: #cecece;
}

.footer_link {
	text-decoration: none;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: rgb(139, 139, 139);
	cursor: pointer;
}
.footer_link:hover {
	color: rgb(78, 72, 72);
}

.image-mobile {
	height: 18px;
	width: 18px;
}

.link_container {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.text-center {
	color: white;
	width: 100%;
	text-align: center;
}
.footer_copy_right {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: rgb(139, 139, 139);
	width: 100% !important;
	text-align: start;
}
.social_link_container {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
@media screen and (max-width: 1024px) {
	.link_container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.footer_copy_right {
		text-align: center;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}
}
