:root .qBraid {
	--primary-clr1: var(--white-100);
	--primary-clr2: black;
	--brand-color-1: #9b12df;
	--brand-color-2: #46096f;
	--brand-color-3: #df0982;
	--brand-color-1-dark: #eb2cff;
	--accent-color-1: #d2137d;
	--accent-color-2: #721eb4;
	--accent-color-3: #721eb426;
	--accent-color-4: #eed8ff;
	--font-clr1: var(--primary-clr2);
	--font-clr2: lightgrey;
	--font-clr3: gray;
	--font-clr4: var(--primary-clr1);
	--alt-font-clr1: #560aa3;
	--alt-font-clr2: #352339;
	--root-bg-clr: var(--primary-clr1);
	--bg-clr1: var(--primary-clr1);
	--bg-clr2: var(--primary-clr1);
	--bg-clr3: #e7e7e7;
	--border-clr1: lightgrey;
	--border-clr2: #dddddd;
	--shadow1: rgba(0, 0, 0, 0.12);
	--white-100: white;
	--white-200: #eee7ee;
	--auth-100: #55276c;
	--auth-200: #2d1539;
}

:root .QuSTEAM {
	--primary-clr1: white;
	--primary-clr2: black;
	--brand-color-1: #4144bb;
	--brand-color-2: #46096f;
	--brand-color-3: #094ddf;
	--brand-color-1-dark: #393ff3;
	--accent-color-1: #bfc5eb;
	--accent-color-2: #4049b7;
	--accent-color-3: #4049b726;
	--accent-color-4: #bfc5eb;
	--font-clr1: var(--primary-clr2);
	--font-clr2: lightgrey;
	--font-clr3: gray;
	--font-clr4: var(--primary-clr1);
	--alt-font-clr1: var(--brand-color-1);
	--alt-font-clr2: #352339;
	--root-bg-clr: var(--primary-clr1);
	--bg-clr1: var(--primary-clr1);
	--bg-clr2: var(--primary-clr1);
	--bg-clr3: #e7e7e7;
	--border-clr1: lightgrey;
	--border-clr2: #dddddd;
	--shadow1: rgba(0, 0, 0, 0.12);
	--white-100: white;
	--white-200: #e9ecf1;
	--auth-100: #302a7b;
	--auth-200: #adadad;
}

.light-theme {
	--font-clr1: var(--primary-clr2);
	--font-clr2: lightgrey;
	--font-clr3: gray;
	--font-clr4: var(--primary-clr1);
	--alt-font-clr1: var(--brand-color-1);
	--alt-font-clr2: #352339;
	--root-bg-clr: var(--primary-clr1);
	--bg-clr1: var(--primary-clr1);
	--bg-clr2: var(--primary-clr1);
	--bg-clr3: #e7e7e7;
	--border-clr1: lightgrey;
	--border-clr2: #dddddd;
	--shadow1: rgba(0, 0, 0, 0.12);
}

.dark-theme {
	--font-clr1: var(--primary-clr1); /* white */
	--font-clr2: lightgrey;
	--font-clr3: gray;
	--brand-color-1: var(--brand-color-1-dark);
	--font-clr4: var(--primary-clr2);
	--alt-font-clr1: #b66dff;
	--alt-font-clr2: #bdaec2;
	--root-bg-clr: #121212;
	--bg-clr1: var(--root-bg-clr);
	--bg-clr2: #2b2b2b;
	--bg-clr3: #514d52;
	--border-clr1: rgb(72, 72, 72);
	--border-clr2: rgb(80, 80, 80);
	--shadow1: rgba(255, 255, 255, 0.12);
	--root-clr: var(--root-bg-clr);
}

/* 4144bb */
/* rgb(65, 68, 187) */
