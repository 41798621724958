/* html {
	--primary-clr1: white;
	--primary-clr2: black;
	--brand-color-1: rgba(155, 18, 223, 1);
	--brand-color-2: rgb(70, 9, 111);
	--brand-color-3: var(--brand-color-3);
	--alt-font-clr1: var(--brand-color-1);
	--alt-font-clr2: rgb(53, 35, 57);
	--bg-clr2: var(--primary-clr1);
	--bg-clr3: #e7e7e7;
	--border-clr1: lightgrey;
	--border-clr2: #dddddd;
} */

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fbfafc;
}

/* body:has(.light-theme) {
	background-color: white;
} */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

body,
.MuiTypography-root,
button,
* {
	font-family: 'Inter', sans-serif;
}

/* this only effect if db-page-container is present in the body */
/* body:has(.db-page-container) {
    background: white !important;
} */

.page-layout {
	padding-left: 38px !important;
	/* padding-left: 3.5rem !important; */
	padding-right: 28px !important;
}

.cursor-pointer {
	cursor: pointer;
}

@media (max-width: 599px) {
	.page-layout {
		/* margin-left: 5px !important; */
		padding-left: 16px !important;
		padding-right: 16px;
	}
}

.react-loader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.outer_fall_back_div {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

::-webkit-scrollbar {
	width: 6px;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(155, 155, 155, 0.5);
	border-radius: 20px;
	border: transparent;
}

.swal2-styled.swal2-confirm {
	padding: 10px 25px !important;
	font-size: 14px !important;
	line-height: 15px;
}

.swal2-styled.swal2-cancel {
	padding: 10px 25px !important;
	font-size: 14px !important;
	line-height: 15px;
}
